import { startStimulusApp } from '@symfony/stimulus-bridge';
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));

// import { registerVueControllerComponents } from '@symfony/ux-vue';
// registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// NEEDED IF YOU USE PINIA

// import {createPinia} from 'pinia';
// document.addEventListener('vue:before-mount', (event) => {
//     const {
//         app,
//     } = event.detail;
//
//     const pinia = createPinia()
//     app.use(pinia);
// });

import * as spriteIcons from './sprite-icons';
import 'bootstrap/js/dist/dropdown';

import * as toc from '@elements/toc';

toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as nav from './nav';
nav.init();

import * as affix from "@elements/affix";

affix.init({
},{
    base: '.js-affix-nav',
    placeholder: '.js-affix-nav__placeholder',
    container: '.js-affix-nav__container'
});


import * as scrollingNav from './scrolling-nav';
scrollingNav.init();


// Todo: Add common JS that is shared between all page types here

import * as renderTemplate from './render-template';
renderTemplate.init();

//
// if you use this, also use the associated CSS (content-visibility.scss)
// import * as contentVisibility from './content-visibility';
// contentVisibility.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as consoleAnimation from './console-animation';
consoleAnimation.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import {find} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

import * as datepicker from "@elements/datepicker";
datepicker.init();
onFind('.js-booking-datepicker',function (element) {
    datepicker.createDatepicker(find('.js-booking-datepicker'),{
        /*options*/
        mode: "range",
        minDate: "today",
        showMonths: 1,
        disableMobile: "true"
    },{
        base: '.js-booking-datepicker',
        input: '.js-booking-datepicker__input',
        altField: '.js-booking-datepicker__alt-field',
        altFieldTo: '.js-booking-datepicker__alt-field-to'
    });
});


import * as tracking from "@elements/tracking-ga4";

let options = {};
tracking.initGtmEventTracking(options);

import * as infoModal from './info-modal';
infoModal.init();

import * as infoOverlay from './info-overlay';
infoOverlay.init();

import * as warning from './warning';
warning.init();

import * as warningModal from './warning-modal';
warningModal.init();

import 'bootstrap/js/dist/tab';

import * as ajaxFormPress from './ajax-form-press';
ajaxFormPress.init();

