import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form-press', function (element) {
        on('submit.ajax-form', (evt) => successHandler( evt.detail, evt.target), element);
        const successHandler = (params, element) => {
        let hiddenInput = findIn('.js-ajax-form-press__input', element);
            // fill hidden input value so it doesn't get deleted by reset all button
            hiddenInput.value = 1;
        };
    });
}
