import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';
import {isMobile} from "./utils";

export function init () {
    if(isMobile()){
        onFind('.js-warning-modal', function(element) {
            const urlParams = new URLSearchParams(window.location.search)
            if(element && !urlParams.has('affiliate')){
                let warningModal = new Modal(element);
                if (!sessionStorage.getItem('warning-modal')) {
                    warningModal.show();
                }
                element.addEventListener('hide.bs.modal', function () {
                    sessionStorage.setItem('warning-modal','closed');
                    warningModal.hide();
                });
            }
        });
    }
}