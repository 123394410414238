export const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function addParamsToUrl(url, ...params) {
    let paramsArr = [];
    params.forEach(function (item) {
        if (Array.isArray(item)) {
            item.forEach(function (subItem) {
                if (subItem) {
                    paramsArr.push(subItem);
                }

            });
        } else {
            if (item) {
                paramsArr.push(item);
            }
        }
    });
    
    let paramString = $.param(paramsArr);

    return url + (url.indexOf('?') < 0 ? '?' : '&') + paramString;
}

export function getConfig(param) {
    if (typeof param !== 'object'){
        param = {
            name: param,
            optional: false,
            validator: null
        }
    }

    if (!window['_config']) {
        throwError('Global var window._config was not set.');
    }

    let configValue = window['_config'][param.name];
    if (configValue) {
        if (param.validator) {
            if (param.validator(configValue)) {
                return configValue;
            } else {
                throwError(
                    param.validator.message || `Value of config "${param.name}" did not match the validator "${param.validator.name}". Value was` ,
                    configValue
                );
            }
        } else {
            return configValue;
        }
    } else if (!param.optional) {

    }
}

export function requireConfigs (...configs) {
    return configs.map(getConfig);
}

export function isUrl(url) {
    return url && typeof url === 'string' && (url.includes('/') || url.includes('#'));
}

export function getPrefixedDataSet(prefix, $domNode) {
    return Object.entries($domNode.data() || {})
        .filter(([key]) => key.startsWith(prefix))
        .map(([key, value]) => ([lowerCaseFirstLetter(key.replace(prefix, '')), value]))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

export function getWeatherTemperatureConfig() {
    const _config = window._config || {};

    let weatherTemperatureConfig = _config.weatherArchiveTemperatureUrl;
    console.log(_config.weatherArchiveTemperatureUrl);

    if (!weatherTemperatureConfig) {
        console.log('warning', 'MISSING CONFIG', 'Missing config "_config.activityConfigUrls". Value of "_config.activityConfigUrls" was not set in _config', true);
    }

    return weatherTemperatureConfig;
}

export function getWeatherWindConfig() {

    const _config = window._config || {};

    let weatherTemperatureConfig = _config.weatherArchiveWindUrl;
    console.log(_config.weatherArchiveWindUrl);

    if (!weatherTemperatureConfig) {
        console.log('warning', 'MISSING CONFIG', 'Missing config "_config.activityConfigUrls". Value of "_config.activityConfigUrls" was not set in _config', true);
    }
    console.log(weatherTemperatureConfig);
    return weatherTemperatureConfig;
}
