import {onFind} from "@elements/init-modules-in-scope";
import {on} from '@elements/dom-utils';
import Modal from 'bootstrap/js/dist/modal';



let selectors = {
    base: '.js-info-modal',
    button: '.js-info-modal__button',
}
export function init () {
    onFind(selectors.base, function(element) {
        const urlParams = new URLSearchParams(window.location.search)
    if(element && !urlParams.has('affiliate')){
        let modalInstance = element.dataset.instance;
        let infoModal = new Modal(element);
        if (!sessionStorage.getItem(modalInstance)) {
            infoModal.show();
        }
        element.addEventListener('hide.bs.modal', function () {
            sessionStorage.setItem( modalInstance,'closed');
        });
        onFind(selectors.button, function(button) {
                on('click', function() {
                    sessionStorage.setItem( modalInstance,'closed');
                },  button);
        });
    }
});
}