import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,on, addClass, findIn} from '@elements/dom-utils';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-console',
    item: '.js-console__item',
    fixedItem: '.js-console__item--fixed',
};

const isMobile = matchMedia('(max-width: 767px)').matches || false;
export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        if(isMobile) return;
        let fixedItem = findIn(selectors.fixedItem, baseElement);
        on('scroll', function () {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                addClass('is-hidden', baseElement);
            } else {
                removeClass('is-hidden', baseElement);
            }
        }, window)
        on('mouseenter', function(){
            removeClass('is-hidden', baseElement);
        }, fixedItem)
    });
}
