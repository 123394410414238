"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,on, addClass, find} from '@elements/dom-utils';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-scrolling-nav',
    quickfinder: '.js-scrolling-nav__quickfinder',
    logo: '.js-scrolling-nav__img',
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        on('scroll', function () {
            let logo = find(selectors.logo);
            let quickfinder = find(selectors.quickfinder);
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                if (quickfinder){
                    addClass('is-hidden', quickfinder);
                }
               addClass('smaller-logo', logo);
            } else {
                if(quickfinder){
                    removeClass('is-hidden', quickfinder);
                }
                removeClass('smaller-logo', logo);
            }
        }, window)
    });
}
