
import {on, find, findIn, findAllIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import Toast from 'bootstrap/js/dist/toast';

export function init () {
    onFind('.js-toast-container ', function (baseElement) {
    let toasts = findAllIn('.js-toast', baseElement);
        toasts.map((toast) => {
        const toastInstance = new Toast(toast);
        const id = toast.getAttribute('data-toast-id');
        if (!sessionStorage.getItem('toast-' + id)) {
                toastInstance.show();
        }else{
            setTimeout(function () {
                toastInstance.hide();
            }, 3);
        }
        let closeBtn = findIn('.js-toast__close', toast);
        if(closeBtn){
            on('click', (e) => {
              sessionStorage.setItem('toast-' + id, false);
                setTimeout(function () {
                    toastInstance.hide();
                }, 300);
            }, closeBtn);
        }
    });
});
}

